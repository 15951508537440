<template>
  <section>
    <div class="modal" ref="column_order_selection">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 class="modal-title">{{ $t("sort_columns") }}</h4>
          </div>
          <div class="modal-body">
            <form class="form-inline" v-if="entries && entries.length">
              <div
                class="row"
                v-for="(entry, ix) in entries"
                v-bind:key="ix * 100"
              >
                <div class="col-xs-2">
                  <div class="form-group text-right" style="width:100%;">
                    <label for="" class="col-form-label no-wrap">
                      {{ $t(ix == 0 ? "sort_by" : "then_by") }}
                    </label>
                  </div>
                </div>
                <div class="col-xs-5">
                  <div class="form-group">
                    <input
                      class="form-control"
                      v-bind:value="$tc(entry.field.title)"
                      disabled
                    />
                  </div>
                </div>
                <div class="col-xs-2 text-left">
                  <div class="radio">
                    <label>
                      <input type="radio" v-bind:checked="entry.asc" disabled />
                      A&#8594;Z
                    </label>
                  </div>
                </div>
                <div class="col-xs-2 text-left">
                  <div class="radio">
                    <label>
                      <input
                        type="radio"
                        v-bind:checked="!entry.asc"
                        disabled
                      />
                      Z&#8594;A
                    </label>
                  </div>
                </div>
                <div class="col-xs-1 text-left last-column">
                  <button
                    class="btn btn-default btn-sm"
                    v-on:click.prevent.stop="delEntry(ix)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </form>
            <form
              class="form-inline"
              v-if="filteredFields && filteredFields.length"
            >
              <div class="row">
                <div class="col-xs-2">
                  <div class="form-group text-right" style="width:100%;">
                    <label for="" class="col-form-label no-wrap">
                      {{ $t(entries.length == 0 ? "sort_by" : "then_by") }}
                    </label>
                  </div>
                </div>
                <div class="col-xs-5">
                  <div class="form-group">
                    <select
                      class="form-control"
                      data-testid="select_column"
                      v-model="new_column.field"
                    >
                      <option
                        v-for="(field, index) in filteredFields"
                        v-bind:key="index"
                        v-bind:value="field"
                        >{{ $tc(field.title) }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-xs-2 text-left">
                  <div class="radio">
                    <label>
                      <input
                        type="radio"
                        v-bind:value="true"
                        v-model="new_column.asc"
                        checked
                      />
                      A&#8594;Z
                    </label>
                  </div>
                </div>
                <div class="col-xs-2 text-left">
                  <div class="radio">
                    <label>
                      <input
                        type="radio"
                        v-bind:value="false"
                        v-model="new_column.asc"
                      />
                      Z&#8594;A
                    </label>
                  </div>
                </div>
                <div class="col-xs-1 text-left last-column">
                  <button
                    v-if="new_column.field"
                    class="btn btn-default btn-sm"
                    data-testid="add_button"
                    v-on:click.prevent.stop="addEntry"
                  >
                    <i class="fa fa-check"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer text-right">
            <button class="btn" v-on:click="close">
              {{ $t("cancel") }}
            </button>
            <button
              class="btn btn-primary"
              v-on:click="save"
              v-bind:disabled="!payload.length"
              data-testid="save_button"
            >
              {{ $t("sort") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ColumnOrderSelection",
  props: {
    fields: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      new_column: {
        field: null,
        asc: true
      },
      entries: []
    };
  },
  computed: {
    filteredFields() {
      let self = this;
      let entries = self.entries.map((i) => {
        return i.field.name;
      });
      let lst = self.fields.filter(function(field) {
        if ("order_id" in field && field.order_id == "") {
          return false;
        }
        return !entries.includes(field.name);
      });
      return lst;
    },
    payload() {
      return this.entries.map(function(i) {
        return (i.asc ? "" : "-") + (i.field.order_id || i.field.name); // it returns column (asc) or -column (dsc)
      });
    }
  },
  methods: {
    addEntry() {
      let self = this;
      self.entries.push(JSON.parse(JSON.stringify(self.new_column)));
      if (self.filteredFields.length) {
        self.$set(self, "new_column", {
          field: (self.new_column.field = self.filteredFields[0]),
          asc: true
        });
      }
    },
    delEntry(i) {
      this.entries = this.entries.filter(function(field, ix) {
        return i != ix;
      });
    },
    save() {
      let self = this;
      self.$emit("sort", self.payload);
      self.close();
    },
    close() {
      if (window.jQuery) {
        $(this.$refs.column_order_selection).modal("hide");
      }
    }
  },
  mounted() {
    let self = this;
    if (window.jQuery) {
      $(self.$refs.column_order_selection)
        .on("hide.bs.modal", function() {
          self.$emit("hide");
        })
        .on("shown.bs.modal", function() {
          self.new_column.field = self.filteredFields[0];
          self.$emit("show");
        })
        .modal("show");
    }
  }
};
</script>

<style scoped>
form.inline-form,
select.form-control,
form.inline-form,
input.form-control {
  min-width: 200px;
  margin-left: 10px;
}
form.inline-form,
div.radio {
  padding-top: 8px;
}
.form-inline .form-group {
  margin-bottom: 15px;
}
.col-form-label {
  padding-top: 5px;
}
.no-wrap {
  white-space: nowrap;
}
.last-column {
  padding-left: 0;
}
</style>
